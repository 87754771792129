<template>
  <createClinic></createClinic>
</template>

<script>

  import createClinic from '../../components/clinics/createClinic.vue'

  export default {
    components: {
      createClinic
    },
    data:()=>({

    })
  }
</script>
